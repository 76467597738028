import React from "react";
import { createPortal } from "react-dom";
import useConfirm from "../../../hooks/useConfirm";
import ModelWrapper from "./ModelWrapper";

const ConfirmDialog = () => {
    const { onConfirm, onCancel, confirmState } = useConfirm();

    const portalElement = document.getElementById("confirm_app");
    const component = confirmState.show ? (
        <ModelWrapper close={onCancel} title={confirmState?.title && confirmState.title} width="w-80">
            {confirmState?.text && <p dangerouslySetInnerHTML={{ __html: confirmState.text }} /> }
            <div className="grid grid-cols-2 text-center pt-5 gap-4">
                <button className="border rounded-full h-10 focus:outline-none focus:ring-2 ring-black/30 ring-offset-2 m-1" onClick={onCancel}>
                    Cancel
                </button>
                <button className="border rounded-full h-10 focus:outline-none focus:ring-2 ring-black/30 ring-offset-2 m-1" onClick={onConfirm}>
                    {confirmState?.confirmText && confirmState.confirmText}
                </button>
            </div>
        </ModelWrapper>
    ) : null;

    return createPortal(component, portalElement);
};
export default ConfirmDialog;
