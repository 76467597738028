import React, { useContext, useState } from "react";
import { AuthContext } from "../../context/auth";
import Post from "../../helpers/ajax/post";

const Login = () => {
    document.title = "Login | Dependable Admin";
    const { loadUserData } = useContext(AuthContext);

    const [password, setPassword] = useState("");
    const [loginError, setLoginError] = useState(null);

    const handleLogin = async (ev) => {
        ev.preventDefault();
        if (!password) {
            return false;
        }
        setLoginError(null);
        try {
            await Post("/login", {
                password: password,
                username: "None",
            });
            await loadUserData();
        } catch (error) {
            setLoginError(error.error);
        }
    };

    return (
        <div className="flex justify-center min-h-screen">
            <form onSubmit={handleLogin} className="w-96 shadow-2xl self-center inline-block rounded-lg p-8">
                <img alt="logo" src="/images/depenable-logo-reg.jpg" className="inline-block mx-auto w-1/2" />
                <h2 className="my-5 text-lg">Admin Login</h2>
                <input required className="w-full block mb-5 rounded-md border focus:outline-none focus:ring-2 ring-offset-2 ring-black/30 p-3" placeholder="Password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                <button className="bg-black block text-white w-full p-3 font-bold rounded-md focus:outline-none focus:ring-2 ring-offset-2 ring-black/30" type="submit">
                    Login
                </button>
                {!!loginError && <p className="text-red-800 text-left mt-4">{loginError}</p>}
            </form>
        </div>
    );
};

export default Login;
