import { useState } from "react";
import { toast } from "react-toastify";
import Post from "../../../helpers/ajax/post";
import ModelWrapper from "./ModelWrapper";

const NewNote = ({ close, colony_id, getColony }) => {
    const [NoteContent, setNoteContent] = useState("");
    const [NoteNotifyUsers, setNoteNotifyUsers] = useState(false);
    const [HasError, setHasError] = useState();

    const addNote = async (ev) => {
        ev.preventDefault();
        try {
            await Post(`/api/notes/add`, {
                content: NoteContent,
                colony_id: colony_id,
                notifyUsers: NoteNotifyUsers,
            });

            toast(`New note has been added`);
            getColony();
            close();
        } catch (error) {
            setHasError(error.error || "an error occurred");
        }
    };

    return (
        <ModelWrapper width={"w-4/6"} close={close} title={"Add Note"}>
            <form onSubmit={addNote}>
                <textarea required placeholder="Note (max 250)" maxLength={250} className="mb-5 w-full border rounded p-3 focus:outline-none focus:ring-2 ring-black/30 ring-offset-2" rows={5} value={NoteContent} onChange={(e) => setNoteContent(e.target.value)}></textarea>
                <div>
                    <label htmlFor="NoteNotifyUsers">
                        <input className="align-middle inline-block mr-3 w-5 h-5" type="checkbox" checked={NoteNotifyUsers} onChange={(e) => setNoteNotifyUsers(e.target.checked)} id="NoteNotifyUsers" />
                        Notify users about this note
                    </label>
                </div>
                {!!HasError && <p className="text-red-800 text-left mt-4">{HasError}</p>}
                <button type="submit" className="float-right mt-3 py-2 px-5 border rounded-full focus:outline-none focus:ring-2 ring-black/30 ring-offset-2">
                    Add Note
                </button>
            </form>
        </ModelWrapper>
    );
};

export default NewNote;
