// import { FaEdit, FaTrashAlt } from "react-icons/fa";

import { useState } from "react";
import NewUser from "../../+global/modals/NewUser";
import ColonyUser from "./partials/ColonyUser";

const Users = (props) => {
    const { users, getColony, colony_id, colony } = props;

    const [AddUserOpen, setAddUserOpen] = useState(false);
    const [SearchString, setSearchString] = useState("");
    const [ShowMinyanStatus, setShowMinyanStatus] = useState(false);
    const userList = users.filter((u) => JSON.stringify(u).toLowerCase().indexOf(SearchString.toLowerCase()) > -1);

    return (
        <div>
            <div className="flex justify-between px-4 mb-2 items-center">
                <h3 className="font-bold text-2xl">Users</h3>
                <input type="text" placeholder="Search users" className="w-60 block mb-3 rounded-md border border-depenable focus:outline-none focus:ring-2 ring-offset-2 ring-black/30 px-3 py-2" value={SearchString} onChange={(e) => setSearchString(e.target.value)} />
                <button
                    className="bg-dependable text-white py-3 px-5 rounded-md "
                    onClick={() => {
                        setAddUserOpen(true);
                    }}
                >
                    Add User
                </button>
            </div>
            <div className="p-3">
                <label htmlFor="ShowMinyanStatus">
                    <input id="ShowMinyanStatus" type={"checkbox"} className="mr-3 w-4 h-4" value={ShowMinyanStatus} onChange={(e) => setShowMinyanStatus(e.target.checked)} />
                    Show minyan Status on users?
                </label>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
                {userList.map((user) => {
                    return <ColonyUser ShowMinyanStatus={ShowMinyanStatus} colony={colony} colony_id={colony_id} getColony={getColony} user={user} key={user._id} />;
                })}
            </div>
            {AddUserOpen && (
                <NewUser
                    colony_id={colony_id}
                    close={() => {
                        setAddUserOpen(false);
                    }}
                    successCallback={getColony}
                />
            )}
        </div>
    );
};

export default Users;
