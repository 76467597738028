import ModelWrapper from "./ModelWrapper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import Post from "../../../helpers/ajax/post";
import moment from "moment";

var inputClass = "w-full block mb-3 rounded-md border focus:outline-none focus:ring-2 ring-offset-2 ring-black/30 px-3 py-2";
var formButtonClass = "bg-black mt-5 block text-white w-full p-3 font-bold rounded-md focus:outline-none focus:ring-2 ring-offset-2 ring-black/30";

const AddToMinyan = (props) => {
    const { close, user, colony, getColony } = props;

    const [Error, setError] = useState(null);
    const [UserAllowedDatas, setUserAllowedDatas] = useState([]);
    const [SlectedEventToAdd, setSlectedEventToAdd] = useState(null);
    const [PoepleCount, setPoepleCount] = useState(1);
    const [IsAnonymous, setIsAnonymous] = useState(false);
    const [IsGuest, setIsGuest] = useState(false);
    const [OnlyIfMinyan, setOnlyIfMinyan] = useState(false);
    const [comingEarlier, setComingEarlier] = useState(false);

    const getSignedUpDates = async () => {
        try {
            console.log(user);
            var localHol = [];
            if (!user.upcomingRecords.find((e) => e.date_title === "Shabbos")) {
                localHol.push({
                    date: colony.upcomingShabbos,
                    name: "Shabbos",
                    holiday: false,
                });
            }
            colony.upcomingHolidays.forEach((ev) => {
                if (!user.upcomingRecords.find((e) => e.date_title === ev.displayName)) {
                    localHol.push({
                        date: ev.date,
                        name: ev.name,
                        holiday: true,
                    });
                }
            });
            setUserAllowedDatas(localHol);
            setSlectedEventToAdd(null);
        } catch (error) {
            setError(error.error);
        }
    };

    const submitAdd = async (ev) => {
        ev.preventDefault();
        try {
            await Post("/api/minyan", {
                poeple_count: PoepleCount,
                is_guest: IsGuest,
                only_if_minyan: OnlyIfMinyan,
                holiday: SlectedEventToAdd.holiday,
                anonymous: IsAnonymous,
                user_id: user._id,
                holidayName: SlectedEventToAdd.name,
                comingEarlier: comingEarlier,
            });
            toast(`Added ${user.first_name} ${user.last_name} to Minyan for ${SlectedEventToAdd.name}`)
            getColony();
            close();
        } catch (error) {
            setError(error.error);
        }
    };

    useEffect(() => {
        getSignedUpDates();
        // eslint-disable-next-line
    }, []);

    return (
        <ModelWrapper width={"w-full md:w-1/2"} close={close} title={`Add ${user.first_name} ${user.last_name} to Minyan`}>
            {!!Error && <>Error: {Error}</>}
            {!Error && (
                <>
                    {!UserAllowedDatas.length && <p>No upcoming dates to show</p>}
                    {!!UserAllowedDatas.length && <p className="text-dependable mb-3">Select Upcoming available Date to add user</p>}
                    {UserAllowedDatas.map((ev) => {
                        const active = SlectedEventToAdd && SlectedEventToAdd.date === ev.date;
                        return (
                            <div key={ev.date}>
                                <button onClick={() => setSlectedEventToAdd(ev)} className="mb-2">
                                    <div className={`inline-block w-4 h-4 border-depenable border mr-2 ${active ? "bg-dependable" : ""}`}></div>
                                    {ev.name} {ev.date}
                                </button>
                            </div>
                        );
                    })}

                    {!!SlectedEventToAdd && (
                        <div className="mt-2">
                            <form onSubmit={submitAdd}>
                                <p>People Count</p>
                                <input required value={PoepleCount} onChange={(e) => setPoepleCount(e.target.value)} placeholder="People Count" type={"number"} className={inputClass} />
                                <label htmlFor="cb_guest" className="flex p-2 align-middle content-center self-center">
                                    <input className="inline-block mr-3 w-5 h-5" id="cb_guest" type="checkbox" checked={IsGuest} onChange={(e) => setIsGuest(e.target.checked)} />
                                    <p>Is a Guest</p>
                                </label>
                                <label htmlFor="cb_only_if" className="flex p-2 align-middle content-center self-center">
                                    <input className="inline-block mr-3 w-5 h-5" id="cb_only_if" type="checkbox" checked={OnlyIfMinyan} onChange={(e) => setOnlyIfMinyan(e.target.checked)} />
                                    <p>Only if there will be Minyan</p>
                                </label>
                                <label htmlFor="cb_Anonymous" className="flex p-2 align-middle content-center self-center">
                                    <input className="inline-block mr-3 w-5 h-5" id="cb_Anonymous" type="checkbox" checked={IsAnonymous} onChange={(e) => setIsAnonymous(e.target.checked)} />
                                    <p>Add as Anonymous</p>
                                </label>
                                {(colony?.openOnThu === true && !['Friday', 'Saturday'].includes(moment(SlectedEventToAdd.date).subtract(2, 'day').format('dddd'))) &&
                                    <label htmlFor="cb_coming_earlier" className="flex p-2 align-middle content-center self-center">
                                        <input className="inline-block mr-3 w-5 h-5" id="cb_coming_earlier" type="checkbox" checked={comingEarlier} onChange={(e) => setComingEarlier(e.target.checked)} />
                                        <p>Coming Earlier</p>
                                    </label>}
                                <button className={formButtonClass}>Add To Minyan</button>
                            </form>
                        </div>
                    )}
                </>
            )}
        </ModelWrapper>
    );
};

export default AddToMinyan;
