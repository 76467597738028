import { useState } from "react";
import { toast } from "react-toastify";
import Put from "../../../helpers/ajax/put";
import ModelWrapper from "./ModelWrapper";

const Policy = ({ close, policy, editMode, getColony }) => {
    
    const [PolicyContent, setPolicyContent] = useState(policy.content);
    const [HasError, setHasError] = useState();

    const updatePolicy = async () => {
        try {
            await Put(`/api/policies/${policy._id}`, {
                content: PolicyContent,
            });
            toast(`Policy has been updated`);
            getColony();
            close();
        } catch (error) {
            setHasError(error.error || "an error occurred");
        }
    };

    return (
        <ModelWrapper width={editMode ? "w-4/6" : ""} close={close} title={policy.title}>
            {editMode ? (
                <>
                    <textarea autoFocus className="w-full border rounded p-3 focus:outline-none focus:ring-2 ring-black/30 ring-offset-2" rows={12} value={PolicyContent} onChange={(e) => setPolicyContent(e.target.value)}></textarea>
                    {!!HasError && <p className="text-red-800 text-left mt-4">{HasError}</p>}
                    <button onClick={updatePolicy} className="float-right mt-3 py-2 px-5 border rounded-full focus:outline-none focus:ring-2 ring-black/30 ring-offset-2">
                        Update Policy
                    </button>
                </>
            ) : (
                <p className="whitespace-pre-wrap">{policy.content}</p>
            )}
        </ModelWrapper>
    );
};

export default Policy;
