import { useState } from "react";
import useConfirm from "../../../hooks/useConfirm";
import { FaTrashAlt } from "react-icons/fa";
import Delete from "../../../helpers/ajax/delete";
import { toast } from "react-toastify";
import NewNote from "../../+global/modals/NewNote";

const Notes = (props) => {
    const { notes, getColony, colony_id } = props;

    const [AddNewNote, setAddNewNote] = useState(false);

    const { confirm } = useConfirm();

    const DeleteNote = async (note_id, note_title) => {
        const confirmDelete = await confirm({
            text: `Are you sure you want to delete <b>${note_title}</b>?`,
            confirmText: "Delete",
            title: "Delete Note",
        });

        if (confirmDelete) {
            try {
                await Delete(`/api/notes/${note_id}`);
                toast(`Note was deleted!`);
                getColony();
            } catch (error) {
                console.log(error);
            }
        }
    };

    return (
        <div>
            <div className="flex justify-between px-4 mb-8 items-center">
                <h3 className="font-bold text-2xl">Notes</h3>
                <button
                    className="bg-dependable text-white py-3 px-5 rounded-md "
                    onClick={() => {
                        setAddNewNote(true);
                    }}
                >
                    Add Note
                </button>
            </div>

            <div className="grid grid-cols-1 gap-4 mt-4">
                {notes.map((note) => {
                    return (
                        <div className="relative col-span-1 rounded-lg shadow-lg border px-3 py-5 flex content-center justify-between" key={note._id}>
                            <div className="pr-12 pl-3">
                                <p className="text-lg font-semibold">{note.title}</p>
                                <p className="">{note.content}</p>
                            </div>
                            <div className="absolute top-3 right-3">
                                <button onClick={() => DeleteNote(note._id, note.title)} className="text-lg p-2 border rounded float-right ring-black/30 focus:ring-2 ring-offset-2 focus:outline-none">
                                    <FaTrashAlt className="text-dependable" />
                                </button>
                            </div>
                        </div>
                    );
                })}
            </div>
            {AddNewNote && <NewNote close={() => setAddNewNote(false)} colony_id={colony_id} getColony={getColony} />}
        </div>
    );
};

export default Notes;
