const Delete = (url) =>
    new Promise((resolve, reject) => {
        var req = {
            method: "DELETE"
        };

        return fetch(url, req)
            .then((response) => {
                response
                    .json()
                    .then(response.ok ? resolve : reject)
                    .catch(reject);
            })
            .catch(reject);
    });

export default Delete;
