import { useState } from "react";
import { toast } from "react-toastify";
import Post from "../../../helpers/ajax/post";
import ModelWrapper from "./ModelWrapper";

var inputClass = "w-full block mb-3 rounded-md border focus:outline-none focus:ring-2 ring-offset-2 ring-black/30 px-3 py-2";
var formButtonClass = "bg-black mt-5 block text-white w-full p-3 font-bold rounded-md focus:outline-none focus:ring-2 ring-offset-2 ring-black/30";

const NewColony = ({ close, successCallback }) => {
    const [colName, setColName] = useState("");
    const [colEmail, setColEmail] = useState("");
    const [colLat, setColLat] = useState("");
    const [colLong, setColLong] = useState("");
    const [colTwitter, setColTwitter] = useState("");
    const [colNotifications, setColNotifications] = useState(false);
    const [colBlockWinterize, setColBlockWinterize] = useState(false);
    const [hasError, setHasError] = useState(null);

    const addColony = async (ev) => {
        ev.preventDefault();
        try {
            var newColony = await Post("/api/colonies/add", {
                name: colName,
                email: colEmail,
                lat: colLat,
                long: colLong,
                twitter: colTwitter,
                notifications: colNotifications,
                blockWinterize: colBlockWinterize,
            });
            toast(`New colony has been added`);
            successCallback(newColony.colony_id);
            close();
        } catch (error) {
            setHasError(error.error || "an error occurred");
        }
    };

    return (
        <ModelWrapper width={'w-96'} close={close} title={"Add new colony"}>
            <form onSubmit={addColony} className="pt-4">
                <input value={colName} onChange={(e) => setColName(e.target.value)} required placeholder="Colony name" type="text" className={inputClass} />
                <input value={colEmail} onChange={(e) => setColEmail(e.target.value)} required placeholder="Email" type="email" className={inputClass} />
                <input value={colLat} onChange={(e) => setColLat(e.target.value)} required placeholder="Latitude" type="text" className={inputClass} />
                <input value={colLong} onChange={(e) => setColLong(e.target.value)} required placeholder="Longitude" type="text" className={inputClass} />
                <input value={colTwitter} onChange={(e) => setColTwitter(e.target.value)} placeholder="Twitter Handle" type="text" className={inputClass} />
                <label htmlFor="notifications" className="flex p-2 align-middle content-center self-center">
                    <input className="inline-block mr-3 w-5 h-5" id="notifications" type="checkbox" checked={colNotifications} onChange={(e) => setColNotifications(e.target.checked)} />
                    <p>Notifications</p>
                </label>
                <label htmlFor="winter" className="flex p-2 align-middle content-center self-center">
                    <input className="inline-block mr-3 w-5 h-5" id="winter" type="checkbox" checked={colBlockWinterize} onChange={(e) => setColBlockWinterize(e.target.checked)} />
                    <p>Block Winterize request</p>
                </label>
                <button className={formButtonClass}>Add Colony</button>
                {!!hasError && <p className="text-red-800 text-left mt-4">{hasError}</p>}
            </form>
        </ModelWrapper>
    );
};

export default NewColony;
