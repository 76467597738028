const Put = (url, data) =>
    new Promise((resolve, reject) => {
        var req = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data), // body data type must match "Content-Type" header
        };

        return fetch(url, req)
            .then((response) => {
                response
                    .json()
                    .then(response.ok ? resolve : reject)
                    .catch(reject);
            })
            .catch(reject);
    });

export default Put;
