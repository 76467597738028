import { useCallback, useEffect, useState } from "react";
import { GrClose } from "react-icons/gr";

const ModelWrapper = (props) => {
    const { children, title, close, width } = props;
    const [FadeIn, setFadeIn] = useState(false);

    const handleUserKeyPress = useCallback(
        (event) => {
            const { key } = event;
            if (key === "Escape") {
                close();
            }
        },
        [close]
    );

    useEffect(() => {
        setFadeIn(true);
    }, []);

    useEffect(() => {
        window.addEventListener("keyup", handleUserKeyPress);
        return () => {
            window.removeEventListener("keyup", handleUserKeyPress);
        };
    }, [handleUserKeyPress]);

    return (
        <div className={`${FadeIn ? "opacity-1" : "opacity-0"} duration-300`}>
            <div onClick={close} className="fixed inset-0 bg-gray-300/50 flex justify-center overflow-y-auto"></div>
            <div className={`${width} max-w-full shadow-2xl self-center inline-block rounded-lg px-6 py-4 bg-white text-left fixed duration-300 ${!FadeIn ? "top-1/3" : "top-1/2"} right-1/2 translate-x-[50%] translate-y-[-50%] z-10`}>
                <button onClick={close} className="absolute top-4 right-4 focus:ring-2 ring-offset-1 ring-black/30 rounded border p-1">
                    <GrClose />
                </button>
                <p className="text-lg font-bold mr-8">{title}</p>
                <div className="max-h-[80vh] overflow-y-auto my-5 px-2 py-1">{children}</div>
            </div>
        </div>
    );
};

export default ModelWrapper;
