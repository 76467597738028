// history component
import React, { useState } from "react";
import { useEffect } from "react";
import { FaCheck } from "react-icons/fa";
import { GrClose } from "react-icons/gr";
import Moment from "react-moment";
import Select from "react-select";
import useConfirm from "../../../../hooks/useConfirm";
import moment from "moment";

const History = ({ ColHistory, setColHistory, colony_id, users, isUpcoming }) => {
    const [Loading, setLoading] = useState(false);
    const [PageError, setPageError] = useState(null);
    const [FilterByUser, setFilterByUser] = useState("all");

    const { confirm } = useConfirm();

    var history = [];
    var reversedHistory = [];

    const sortedUsers = users.sort((a, b) => {
        if (a.first_name < b.first_name) {
            return -1;
        }
        if (a.first_name > b.first_name) {
            return 1;
        }
        return 0;
    });

    const [Userlist] = useState([
        { value: "all", label: "All" },
        ...sortedUsers.map((user) => ({
            value: user.user_id,
            label: `${user.first_name} ${user.last_name}`,
        })),
    ]);
    const fetchColHistory = async () => {
        try {
            setLoading(true);
            const res = await fetch(`/api/colonies/${colony_id}/history`);
            const data = await res.json();
            if (data.error) {
                setPageError(data.error);
            } else {
                setPageError(null);
                setColHistory(data);
            }
        } catch (error) {
            setPageError(error.message);
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const unreserveMussaf = async (id) => {
        const confirmDelete = await confirm({
            text: `Are you sure you want to unreserve Mussaf?`,
            confirmText: "Unreserve",
            title: "Unreserve Mussaf?",
        });

        if (confirmDelete) {
            try {
                const res = await fetch(`/api/minyan/${id}/unreserve`, {
                    method: "Get",
                });
                const data = await res.json();
                if (!res.ok) {
                    throw new Error(data.error);
                }

                fetchColHistory();
            } catch (error) {
                console.log(error);
                alert(error.message || "Something went wrong");
            }
        }
    };

    useEffect(() => {
        if (!ColHistory) {
            fetchColHistory();
        } else {
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ColHistory]);

    if (ColHistory && !Loading && !PageError) {
        if (FilterByUser === "all") {
            history = ColHistory.data;
        } else {
            history = ColHistory.data.filter((item) => {
                return item.user_id === FilterByUser;
            });
        }

        // sort history by date
        history.sort((a, b) => {
            if (a.date < b.date) {
                return -1;
            }
            if (a.date > b.date) {
                return 1;
            }
            return 0;
        });
        // link history to user
        history.forEach((item) => {
            item.user = users.find((user) => user.user_id === item.user_id);
        });
        // group history by date
        const groupedHistory = history.reduce((r, a) => {
            var dateFormatted = moment(a.date).format("YYYY-MM-DD");
            r[dateFormatted] = [...(r[dateFormatted] || []), a];
            return r;
        }, {});

        if (isUpcoming) {
            reversedHistory = Object.keys(groupedHistory).reduce((obj, key) => {
                obj[key] = groupedHistory[key];
                return obj;
            }, {});
        } else {
            reversedHistory = Object.keys(groupedHistory)
                .reverse()
                .reduce((obj, key) => {
                    obj[key] = groupedHistory[key];
                    return obj;
                }, {});
        }
    }

    return (
        <div>
            {PageError && <p>{PageError}</p>}
            {Loading && <p>Loading...</p>}
            {ColHistory && (
                <div>
                    <div className="flex justify-between px-4 mb-8 items-center">
                        <h3 className="font-bold text-2xl">{isUpcoming ? "Upcoming Signups" : "Past History"} </h3>
                        <div className="flex items-center">
                            <label className="mr-2">Filter by user:</label>

                            <Select
                                className=" border border-depenable  rounded-md w-72"
                                onChange={(e) => {
                                    setFilterByUser(e.value);
                                }}
                                defaultValue={Userlist[0]}
                                isClearable={false}
                                isSearchable={true}
                                name="color"
                                options={Userlist}
                            />
                        </div>
                    </div>
                    <div>
                        {Object.keys(reversedHistory).map((date) => {
                            const isFuture = new Date(date) > new Date();
                            if (isFuture && !isUpcoming) return null;
                            if (!isFuture && isUpcoming) return null;
                            return (
                                <div key={date} className="mb-8">
                                    <h3 className="font-bold text-xl mb-4">
                                        {reversedHistory[date][0].date_title}
                                        {" - "}
                                        <Moment format="MM/DD/YYYY">{date}</Moment>
                                    </h3>
                                    <table className="table-fixed w-full">
                                        <thead>
                                            <tr>
                                                <th className="border-b dark:border-slate-600 font-medium p-4 pl-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left">
                                                    Name
                                                </th>
                                                <th className="border-b dark:border-slate-600 font-medium p-4 pl-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left">
                                                    Unit
                                                </th>
                                                <th className="border-b dark:border-slate-600 font-medium p-4 pl-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left">
                                                    People
                                                </th>
                                                <th className="border-b dark:border-slate-600 font-medium p-4 pl-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left">
                                                    Reserved Maftir/Mussaf
                                                </th>
                                                <th className="border-b dark:border-slate-600 font-medium p-4 pl-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left">
                                                    Sign-up time
                                                </th>
                                                <th className="border-b dark:border-slate-600 font-medium p-4 pl-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left"></th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white dark:bg-slate-800">
                                            {reversedHistory[date].map((item) => {
                                                // if no user
                                                if (!item.user) {
                                                    return null;
                                                }

                                                return (
                                                    <tr key={item._id}>
                                                        <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                                            {item.user.first_name} {item.user.last_name}
                                                        </td>
                                                        <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                                            {item.user.unit}
                                                        </td>
                                                        <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                                            {item.poeple_count}
                                                        </td>
                                                        <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                                            {item.reservedMussaf && (
                                                                <div>
                                                                    <FaCheck className="inline-block mr-4" />
                                                                    {isUpcoming && (
                                                                        <button
                                                                            onClick={unreserveMussaf.bind(this, item._id)}
                                                                            className="hover:underline text-red-400 "
                                                                        >
                                                                            Unreserve
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            )}
                                                            {!item.reservedMussaf && <GrClose />}
                                                        </td>
                                                        <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                                            {!!item.timeAdded && (
                                                                <Moment format="D/M/YYYY, ddd, hh:mm A">{item.timeAdded}</Moment>
                                                            )}
                                                        </td>
                                                        <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                                            {item.only_if_minyan ? "Only if minyan" : ""}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                    <div className="flex flex-col"></div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </div>
    );
};

export default History;
