import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ErrorComponent from "../+global/ErrorComponent";
import NewColony from "../+global/modals/NewColony";
import Get from "../../helpers/ajax/get";
import { GiFamilyHouse } from "react-icons/gi";

const Home = () => {
    document.title = "Home | Dependable Admin";

    const [hasError, setHasError] = useState(null);
    const [colonies, setColonies] = useState([]);
    const [addColonyOpen, setAddColonyOpen] = useState(false);
    const navigate = useNavigate();

    const getColonies = async () => {
        try {
            var colonies = await Get("/api/colonies");
            setColonies(colonies.data);
        } catch (error) {
            setHasError(error.error || "an error occurred");
        }
    };

    useEffect(() => {
        getColonies();
    }, []);

    if (!!hasError) return <ErrorComponent error={hasError} />;

    return (
        <div className="max-w-6xl mx-auto text-left">
            <div className="py-5">
                <h2 className="text-4xl font-bold inline-block">Colonies</h2>
                <button className="float-right bg-dependable text-white py-3 px-5 rounded-md " onClick={() => setAddColonyOpen(true)}>
                    Add colony
                </button>
                <Link to="/signupdata" className="mr-4 float-right border border-depenable text-dependable py-3 px-5 rounded-md">
                    See signup data
                </Link>
            </div>
            <div className="grid grid-cols-4 py-5 gap-8">
                {colonies.map((colony) => {
                    return (
                        <Link to={`/colony/${colony.colony_id}`} key={colony.colony_id} className="rounded-lg shadow-md hover:shadow-xl duration-150 border p-5 text-center col-span-2 md:col-span-1">
                            <GiFamilyHouse className="m-auto text-7xl text-[#14518e]" />
                            <p className="m-auto text-xl mt-3">{colony.name}</p>
                        </Link>
                    );
                })}
            </div>
            {addColonyOpen && (
                <NewColony
                    close={() => setAddColonyOpen(false)}
                    successCallback={(colony_id) => {
                        navigate(`/colony/${colony_id}`);
                    }}
                />
            )}
        </div>
    );
};

export default Home;
