import { useState } from "react";
import { toast } from "react-toastify";
import Post from "../../../helpers/ajax/post";
import ModelWrapper from "./ModelWrapper";

const NewPolicy = ({ close, colony_id, getColony }) => {
    const [PolicyContent, setPolicyContent] = useState("");
    const [PolicyTitle, setPolicyTitle] = useState("");
    const [PolicyNotifyUsers, setPolicyNotifyUsers] = useState(false);
    const [HasError, setHasError] = useState();

    const addPolicy = async (ev) => {
        ev.preventDefault();
        try {
            await Post(`/api/policies/add`, {
                content: PolicyContent,
                title: PolicyTitle,
                colony_id: colony_id,
                notifyUsers: PolicyNotifyUsers
            });

            toast(`New policy has been added`);
            getColony();
            close();
        } catch (error) {
            setHasError(error.error || "an error occurred");
        }
    };

    return (
        <ModelWrapper width={"w-4/6"} close={close} title={'Add Policy'}>
            <form onSubmit={addPolicy}>
                <input required placeholder="Policy Title" type="text" value={PolicyTitle} onChange={(e) => setPolicyTitle(e.target.value)} className="mb-5 block w-full border rounded p-3 focus:outline-none focus:ring-2 ring-black/30 ring-offset-2" />
                <textarea required placeholder="Policy" className="mb-5 w-full border rounded p-3 focus:outline-none focus:ring-2 ring-black/30 ring-offset-2" rows={10} value={PolicyContent} onChange={(e) => setPolicyContent(e.target.value)}></textarea>
                <div>
                    <label htmlFor="PolicyNotifyUsers">
                        <input className="align-middle inline-block mr-3 w-5 h-5" type="checkbox" checked={PolicyNotifyUsers} onChange={(e) => setPolicyNotifyUsers(e.target.checked)} id="PolicyNotifyUsers" />
                        Notify users about this policy
                    </label>
                </div>
                {!!HasError && <p className="text-red-800 text-left mt-4">{HasError}</p>}
                <button type="submit" className="float-right mt-3 py-2 px-5 border rounded-full focus:outline-none focus:ring-2 ring-black/30 ring-offset-2">
                    Add Policy
                </button>
            </form>
        </ModelWrapper>
    );
};

export default NewPolicy;
