import { useRef, useState } from "react";
import { FaCheck, FaEdit, FaTimes, FaTrash } from "react-icons/fa";
import Moment from "react-moment";
import { toast } from "react-toastify";
import AddToMinyan from "../../../+global/modals/AddToMinyan";
import Delete from "../../../../helpers/ajax/delete";
import Put from "../../../../helpers/ajax/put";
import useConfirm from "../../../../hooks/useConfirm";

const ColonyUser = (props) => {
    const { user, getColony, colony, ShowMinyanStatus } = props;
    const { confirm } = useConfirm();

    const [Unit, setUnit] = useState(user.unit);
    const [Phone, setPhone] = useState(user.phone);
    const [EditUnit, setEditUnit] = useState(false);
    const [EditPhone, setEditPhone] = useState(false);
    const [notifications, setNotifications] = useState(user.notifications);
    const [notificationMethod, setNotificationMethod] = useState(user.notificationMethod);
    const [BypassCode, setBypassCode] = useState((user?.bypassCode));
    const [EditBypassCode, setEditBypassCode] = useState(false);
    const [AddToMinyanOpen, setAddToMinyanOpen] = useState(false);

    const unitInputRef = useRef();

    const deleteUser = async () => {
        const confirmDelete = await confirm({
            text: `Are you sure you want to delete ${user.first_name} ${user.last_name}? <br/> (this will remove all their data)`,
            confirmText: "Delete",
            title: "Delete User",
        });
        if (confirmDelete) {
            try {
                await Delete(`/api/users/${user._id}`);
                toast(`User deleted!`);
                getColony();
            } catch (error) {
                console.log(error);
            }
        }
    };

    const UpdateUser = async (updateField, updateValue) => {
        try {
            await Put(`/api/users/${user._id}`, {
                updateField: updateField,
                updateValue: updateValue,
            });
            toast(<div>
                User's {updateField.replace(/notificationMethod/g, "Notification Method")
                    .replace(/bypassCode/g, "Bypass Code")} updated to {updateValue.replace(/^E$/g, "Email")
                        .replace(/^T$/g, "Text")
                        .replace(/^N$/g, "Off")
                        .replace(/^A$/g, "Every Sign Up")
                        .replace(/^M$/g, "Once There's Minyan")}
                {updateField === "bypassCode" && (
                    <text className='text-red-600 text-lg font-bold'>
                        <br />
                        Bypass Code expires in 24 hours
                    </text>
                )}
            </div>);
            getColony();
        } catch (error) {
            alert(error.error || "Error updating user");
            console.log(error);
        }
    };

    const DeleteRecord = async (recId) => {
        try {
            await Delete(`/api/minyan/${recId}`);
            toast("Removed from Minyan");
            getColony();
        } catch (error) {
            console.log(error.error);
        }
    };

    return (
        <div className="rounded-lg shadow-lg border p-3 relative">
            <button
                onClick={deleteUser}
                className="absolute bg-white text-lg p-2 border rounded ring-black/30 focus:ring-2 ring-offset-2 focus:outline-none top-2 right-2"
            >
                <FaTrash className="text-dependable " />
            </button>
            <div>
                <table className="border-separate">
                    <tbody>
                        <tr>
                            <td className="pr-2 font-light">Name:</td>
                            <td>
                                {user.first_name} {user.last_name}
                            </td>
                        </tr>
                        <tr>
                            <td className="pr-2 font-light">Email:</td>
                            <td>{user.email}</td>
                        </tr>
                        {!!user.username && (
                            <tr>
                                <td className="pr-2 font-light">Username:</td>
                                <td>{user.username}</td>
                            </tr>
                        )}
                        <tr>
                            <td className="pr-2 font-light">Phone:</td>
                            <td>
                                {!EditPhone && (
                                    <>
                                        {user.phone}
                                        <button
                                            onClick={() => {
                                                setEditPhone(true);
                                            }}
                                            className="ml-2 align-middle"
                                        >
                                            <FaEdit className="text-dependable" />
                                        </button>
                                    </>
                                )}
                                {EditPhone && (
                                    <div className="relative ">
                                        <input
                                            autoFocus
                                            className="w-full block rounded-md border focus:outline-none focus:ring-2 ring-offset-2 ring-black/30 px-2 py-1"
                                            type="text"
                                            value={Phone ? Phone : ""}
                                            onChange={(e) => setPhone(e.target.value)}
                                        />
                                        <button
                                            onClick={() => {
                                                setPhone(user.phone);
                                                setEditPhone(false);
                                            }}
                                            className="absolute text-lg p-1 focus:outline-none top-1 right-0"
                                        >
                                            <FaTimes className="text-dependable " />
                                        </button>

                                        <button
                                            disabled={!Phone}
                                            onClick={() => {
                                                let phone = Phone.replace(/\D/g, "");
                                                if (phone.length !== 10) {
                                                    alert("Invalid phone number");
                                                    return;
                                                }

                                                UpdateUser("phone", phone);
                                                setEditPhone(false);
                                            }}
                                            className="absolute text-lg p-1 focus:outline-none top-1 right-6 disabled:opacity-50"
                                        >
                                            <FaCheck className="text-dependable " />
                                        </button>
                                    </div>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td className="pr-2 font-light">Unit:</td>
                            <td>
                                {!EditUnit && (
                                    <>
                                        {user.unit}
                                        <button
                                            onClick={() => {
                                                setEditUnit(true);
                                                console.log(unitInputRef);
                                            }}
                                            className="ml-2 align-middle"
                                        >
                                            <FaEdit className="text-dependable" />
                                        </button>
                                    </>
                                )}
                                {EditUnit && (
                                    <div className="relative ">
                                        <input
                                            autoFocus
                                            className="w-full block rounded-md border focus:outline-none focus:ring-2 ring-offset-2 ring-black/30 px-2 py-1"
                                            type="text"
                                            value={Unit}
                                            onChange={(e) => setUnit(e.target.value)}
                                        />
                                        <button
                                            onClick={() => {
                                                setUnit(user.unit);
                                                setEditUnit(false);
                                            }}
                                            className="absolute text-lg p-1 focus:outline-none top-1 right-0"
                                        >
                                            <FaTimes className="text-dependable " />
                                        </button>

                                        <button
                                            disabled={!Unit}
                                            onClick={() => {
                                                UpdateUser("unit", Unit);
                                                setEditUnit(false);
                                            }}
                                            className="absolute text-lg p-1 focus:outline-none top-1 right-6 disabled:opacity-50"
                                        >
                                            <FaCheck className="text-dependable " />
                                        </button>
                                    </div>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td className="pr-2 font-light">Notification Trigger:</td>
                            <td className="">
                                <select className="border border-depenable  rounded-md w-44 bg-white text-sm p-1" value={notifications} onChange={(e) => setNotifications(e.target.value)}>
                                    <option value="N">Off</option>
                                    <option value="A">Every Sign Up</option>
                                    <option value="M">Once There's Minyan</option>
                                </select>
                            </td>
                            <td>
                                {(notifications !== user.notifications || notificationMethod !== user.notificationMethod) &&
                                    <button className="bg-dependable text-white p-2 rounded-md "
                                        onClick={() => {
                                            if (notifications !== user.notifications)
                                                UpdateUser("notifications", notifications);
                                            if (notificationMethod !== user.notificationMethod)
                                                UpdateUser("notificationMethod", notificationMethod);
                                        }}>
                                        Update
                                    </button>
                                }
                            </td>
                        </tr>
                        {notifications !== "N" &&
                            <tr>
                                <td className="pr-2 font-light">Notification Method:</td>
                                <td>
                                    <select className="border border-depenable  rounded-md w-44 bg-white text-sm p-1" value={notificationMethod} onChange={(e) => setNotificationMethod(e.target.value)}>
                                        <option value="E">Email</option>
                                        <option value="T">Text</option>
                                    </select>
                                </td>
                            </tr>
                        }
                        <tr>
                            <td className="pr-2 font-light">
                                Bypass Code:
                            </td>
                            <td>
                                {!EditBypassCode && (
                                    <>
                                        {(user?.bypassCode ||
                                            "Not set")}
                                        <button onClick={() => { setEditBypassCode(true); }} className="ml-2 align-middle">
                                            <FaEdit className="text-dependable" />
                                        </button>
                                    </>
                                )}
                                {EditBypassCode && (
                                    <div className="relative ">
                                        <input autoFocus onChange={(e) => setBypassCode(e.target.value)} className="w-full block rounded-md border focus:outline-none focus:ring-2 ring-offset-2 ring-black/30 px-2 py-1" type="text" value={BypassCode ?
                                            BypassCode : ""} />
                                        <button onClick={() => {
                                            setBypassCode(user?.bypassCode);
                                            setEditBypassCode(false);
                                        }} className="absolute text-lg p-1 focus:outline-none top-1 right-0">
                                            <FaTimes className="text-dependable " />
                                        </button>
                                        <button disabled={!BypassCode} onClick={() => {
                                            UpdateUser("bypassCode", BypassCode);
                                            setEditBypassCode(false);
                                        }} className="absolute text-lg p-1 focus:outline-none top-1 right-6 disabled:opacity-50">
                                            <FaCheck className="text-dependable " />
                                        </button>
                                    </div>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td className="pr-2 font-light">Role:</td>
                            <td>{user.admin ? "Admin" : "User"}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="grid grid-cols-12 gap-3 mt-3">
                <button
                    className="border-depenable text-depborder-depenable border rounded-full col-span-5 text-xs px-2 py-1"
                    onClick={() => {
                        UpdateUser("admin", !user.admin);
                    }}
                >
                    Make {user.admin ? "not " : ""}admin
                </button>
                <button
                    onClick={() => setAddToMinyanOpen(true)}
                    className="border-depenable text-depborder-depenable border rounded-full col-span-5 text-xs px-2 py-1"
                >
                    Add to minyan
                </button>
            </div>

            {ShowMinyanStatus && (
                <div className="pt-5">
                    {!!user.upcomingRecords.length && (
                        <>
                            <p className="mb-1 ">Upcoming Minyan Signups</p>
                            <table className="w-full table-auto">
                                <thead>
                                    <tr className="border-b-2">
                                        <th>Type</th>
                                        <th>Date</th>
                                        <th>People</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {user.upcomingRecords.map((record) => {
                                        return (
                                            <tr key={record.date} className="border-b">
                                                <td>{record.date_title}</td>
                                                <td>
                                                    <Moment date={record.date} format=" MMMM Do" />
                                                </td>
                                                <td>{record.poeple_count}</td>
                                                <td>
                                                    <button className="text-red-500" onClick={() => DeleteRecord(record._id)}>
                                                        Remove
                                                    </button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </>
                    )}
                    {!user.upcomingRecords.length && <p>No upcoming dates</p>}
                </div>
            )}

            {AddToMinyanOpen && (
                <AddToMinyan getColony={getColony} colony={colony} user={user} close={() => setAddToMinyanOpen(false)} />
            )}
        </div>
    );
};

export default ColonyUser;
