import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ErrorComponent from "../+global/ErrorComponent";
import Get from "../../helpers/ajax/get";
import { FaCheck, FaChevronLeft, FaEdit } from "react-icons/fa";
import Policies from "./sections/Policies";
import Users from "./sections/Users";
import EditHoliday from "../+global/modals/EditHoliday";
import Notes from "./sections/Notes";
import Post from "../../helpers/ajax/post";
import History from "./sections/partials/History";

const Colony = () => {
    const [hasError, setHasError] = useState(null);
    const [colony, setColony] = useState(null);
    const [currentTab, setCurrentTab] = useState("users");
    const [facilitiesControlled, setFacilitiesControlled] = useState({});
    const [upcomingHolidays, setUpcomingHolidays] = useState([]);
    const [EditHolidayOpen, setEditHolidayOpen] = useState(false);
    const [EditingShulPolicy, setEditingShulPolicy] = useState(false);
    const [EditMinmumUnitsRequired, setEditMinmumUnitsRequired] = useState(false);
    const [MinimumUnitsRequiredForMinyan, setMinimumUnitsRequiredForMinyan] = useState("N/A");
    const [ShulPolicy, setShulPolicy] = useState("");
    const [ColHistory, setColHistory] = useState(null);

    document.title = `${colony?.name || "Loading"} | Dependable Admin`;

    const { colony_id, section } = useParams();

    useEffect(() => {
        if (section) {
            setCurrentTab(section);
        }
    }, [section]);

    const getColony = async () => {
        try {
            var colonies = await Get(`/api/colonies/${colony_id}`);
            if (colonies.data.shulOpenAt === undefined) colonies.data.shulOpenAt = [];
            if (colonies.data.mikvahOpenAt === undefined) colonies.data.mikvahOpenAt = [];
            setColony(colonies.data);
            setUpcomingHolidays(colonies.data.upcomingHolidays);
            setShulPolicy(colonies.data.mikvahShulPolicy);
            setMinimumUnitsRequiredForMinyan(colonies.data.minimumUnitsRequiredForMinyan);
            setFacilitiesControlled(colonies.data.facilitiesControlled || []);
        } catch (error) {
            setHasError(error.error || "an error occurred");
        }
    };

    const UpdateShulPolicy = async () => {
        try {
            await Post(`/api/colonies/${colony_id}/setShulMikvahPolicy`, {
                mikvahShulPolicy: ShulPolicy,
            });
            setEditingShulPolicy(false);
            getColony();
        } catch (error) {
            setHasError(error.error || "an error occurred");
        }
    };
    const UpdateMinimumUnitsRequired = async () => {
        try {
            await Post(`/api/colonies/${colony_id}/updateSettings`, { minimumUnitsRequiredForMinyan: MinimumUnitsRequiredForMinyan });
            setEditMinmumUnitsRequired(false);
            getColony();
        } catch (error) {
            setHasError(error.error || "an error occurred");
        }
    };

    useEffect(() => {
        getColony();
        // eslint-disable-next-line
    }, []);

    if (!!hasError) return <ErrorComponent error={hasError} />;

    if (!hasError && !colony) return <div>Loading</div>;
    function isDateOpen(date, openDates) {
        return openDates.filter((openDate) => (new Date(date).getUTCDate() + "-" + new Date(date).getUTCMonth() + "-" + new Date(date).getUTCFullYear() === new Date(openDate).getUTCDate() + "-" + new Date(openDate).getUTCMonth() + "-" + new Date(openDate).getUTCFullYear())).length > 0;
    }
    async function sendFacilitiesControlled(date, isControlled) {
        try {
            await Post(`/api/colonies/${colony_id}/setFacilitiesControlled`, { [date]: isControlled });
            setFacilitiesControlled({ ...facilitiesControlled, [date]: isControlled });
            await getColony();
        } catch (error) {
            setHasError(error.error || "an error occurred");
        }
    }
    async function toggleHasMikvah() {
        try {
            await Post(`/api/colonies/${colony_id}/updateSettings`, { hasMikvah: colony.hasMikvah === undefined ? false : !colony.hasMikvah });
        } catch (error) {
            setHasError(error.error || "an error occurred");
        }
        setColony({ ...colony, hasMikvah: colony.hasMikvah === undefined ? false : !colony.hasMikvah });
        getColony();
    }
    async function toggleOpenOnThu() {
        try {
            await Post(`/api/colonies/${colony_id}/updateSettings`, { openOnThu: colony.openOnThu === undefined ? true : !colony.openOnThu });
        } catch (error) {
            setHasError(error.error || "an error occurred");
        }
        setColony({ ...colony, openOnThu: colony.openOnThu === undefined ? true : !colony.openOnThu });
        getColony();
    }
    async function toggleShulMikvah(date, field) {
        if (isDateOpen(date, colony[field])) {
            await Post(`/api/colonies/${colony_id}/setShulMikvah/${field}`, colony[field] = colony[field].filter(item => (new Date(item).getTime()) !== (new Date(date).getTime())));
        } else {
            await Post(`/api/colonies/${colony_id}/setShulMikvah/${field}`, colony[field] = [...colony[field], new Date(date)]);
        }
        setColony({ ...colony, [field]: colony[field] });
        getColony();
    }

    return (
        <div className="max-w-6xl mx-auto text-left">
            <div className="py-5">
                <h2 className="text-2xl md:text-4xl font-bold mb-5 truncate">
                    <Link to={"/"} className="mr-3 md:mr-4 inline-block hover:underline text-dependable">
                        <FaChevronLeft className="inline-block" />
                    </Link>
                    {colony.name}
                </h2>
                <div className="md:pl-14">
                    <p className="text-lg">
                        Colony Info:{" "}
                        <a
                            className="hover:underline text-dependable inline-block ml-3"
                            href={`/api/colonies/${colony_id}/download`}
                            rel="noreferrer"
                            target="_blank"
                        >
                            Download
                        </a>
                    </p>
                    <table className="my-3">
                        <tbody className="whitespace-no-wrap">
                            <tr>
                                <td className="text-gray-500">Shabbos {colony.upcomingShabbos}</td>
                            </tr>
                            <tr>
                                <td>
                                    <label htmlFor={`facilitiesControlled_${colony.upcomingShabbos}`}>
                                        <input type={"checkbox"} id={`facilitiesControlled_${colony.upcomingShabbos}`} className="mr-3 w-4 h-4" checked={facilitiesControlled[colony.upcomingShabbos] || facilitiesControlled[colony.upcomingShabbos] === undefined} onChange={(e) => { sendFacilitiesControlled(colony.upcomingShabbos, e.target.checked) }} />
                                        Facilities controlled automatically
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <td className="pr-3 font-light">Shul:</td>
                                <td>
                                    {isDateOpen(colony.upcomingShabbos, colony.shulOpenAt || []) ? "Open" : "Closed"}
                                    {(facilitiesControlled[colony.upcomingShabbos] === false) &&
                                        <button className="ml-4 align-middle" onClick={() => toggleShulMikvah(colony.upcomingShabbos, "shulOpenAt")} >
                                            <FaEdit className="text-dependable" />
                                        </button>
                                    }
                                </td>
                            </tr>
                            <tr className="border-b-2">
                                <td className="pr-3 font-light">Mikvah:</td>
                                <td>
                                    {colony?.hasMikvah === false ? "N/A" : ""}
                                    {colony?.hasMikvah !== false ? isDateOpen(colony.upcomingShabbos, colony.mikvahOpenAt || []) ? "Open" : "Closed" : ""}
                                    {(facilitiesControlled[colony.upcomingShabbos] === false && colony?.hasMikvah !== false) &&
                                        <button className="ml-4 align-middle" onClick={() => toggleShulMikvah(colony.upcomingShabbos, "mikvahOpenAt")} >
                                            <FaEdit className="text-dependable" />
                                        </button>
                                    }
                                </td>
                            </tr>
                            {upcomingHolidays.map((holiday) => (
                                <>
                                    <tr>
                                        <td className="text-gray-500">{holiday.displayName} {holiday.date}</td>
                                    </tr>
                                    <tr>
                                        <label htmlFor={`facilitiesControlled_${holiday.date}}`}>
                                            <input type={"checkbox"} id={`facilitiesControlled_${holiday.date}}`} className="mr-3 w-4 h-4" checked={facilitiesControlled[holiday.date] || facilitiesControlled[holiday.date] === undefined} onChange={(e) => { sendFacilitiesControlled(holiday.date, e.target.checked) }} />
                                            Facilities controlled automatically
                                        </label>
                                    </tr>
                                    <tr>
                                        <td className="pr-3 font-light">Shul:</td>
                                        <td>
                                            {isDateOpen(holiday.date, colony.shulOpenAt || []) ? "Open" : "Closed"}
                                            {(facilitiesControlled[holiday.date] === false) &&
                                                <button className="ml-4 align-middle" onClick={() => toggleShulMikvah(holiday.date, "shulOpenAt")} >
                                                    <FaEdit className="text-dependable" />
                                                </button>
                                            }
                                        </td>
                                    </tr>
                                    <tr className="border-b-2">
                                        <td className="pr-3 font-light">Mikvah:</td>
                                        <td>
                                            {colony?.hasMikvah === false ? "N/A" : ""}
                                            {colony?.hasMikvah !== false ? isDateOpen(holiday.date, colony.mikvahOpenAt || []) ? "Open" : "Closed" : ""}
                                            {(facilitiesControlled[holiday.date] === false && colony?.hasMikvah !== false) &&
                                                <button className="ml-4 align-middle" onClick={() => toggleShulMikvah(holiday.date, "mikvahOpenAt")} >
                                                    <FaEdit className="text-dependable" />
                                                </button>
                                            }
                                        </td>
                                    </tr>
                                </>
                            ))}
                            <tr>
                                <td className="pr-3 font-light">Notifications:</td>
                                <td>{colony.notification ? "Enabled" : "Disabled"}</td>
                            </tr>
                            <tr>
                                <td className="pr-3 font-light">Winterization:</td>
                                <td>{!colony.blockWinterize ? "Enabled" : "Disabled"}</td>
                            </tr>
                            <tr>
                                <td className="pr-3 font-light">Minimum units required to open Shul:</td>
                                <td>
                                    {!EditMinmumUnitsRequired && (
                                        <>
                                            {colony.minimumUnitsRequiredForMinyan || '0'}
                                            <button className="ml-4 align-middle" onClick={() => setEditMinmumUnitsRequired(true)}>
                                                <FaEdit className="text-dependable" />
                                            </button>
                                        </>
                                    )}
                                    {EditMinmumUnitsRequired && (
                                        <>
                                            <input
                                                type="number"
                                                placeholder="Minmum Units Required"
                                                className="w-96 inline-block mb-3 rounded-md border border-depenable focus:outline-none focus:ring-2 ring-offset-2 ring-black/30 px-3 py-2"
                                                value={MinimumUnitsRequiredForMinyan}
                                                onChange={(e) => setMinimumUnitsRequiredForMinyan(e.target.value)}
                                            />
                                            <button className="ml-4 align-middle" onClick={UpdateMinimumUnitsRequired}>
                                                <FaCheck className="text-dependable" />
                                            </button>
                                        </>
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td className="pr-3 font-light">Support Email:</td>
                                <td>{colony.email}</td>
                            </tr>
                            <tr>
                                <td className="pr-3 font-light">Enabled holidays:</td>
                                <td>
                                    {!colony.holidays.length && <>No Holidays enabled</>}
                                    {!!colony.holidays.length && <>{colony.holidays.map((hol) => hol.displayName).join(", ")}</>}

                                    <button className="ml-4 align-middle" onClick={() => setEditHolidayOpen(true)}>
                                        <FaEdit className="text-dependable" />
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td className="pr-3 font-light">Shul/Mikvah Policy:</td>
                                <td>
                                    {!EditingShulPolicy && (
                                        <>
                                            {colony.mikvahShulPolicy || "N/A"}
                                            <button className="ml-4 align-middle" onClick={() => setEditingShulPolicy(true)}>
                                                <FaEdit className="text-dependable" />
                                            </button>
                                        </>
                                    )}
                                    {EditingShulPolicy && (
                                        <>
                                            <input
                                                type="text"
                                                placeholder="Shul/Mikvah Policy"
                                                className="w-96 inline-block mb-3 rounded-md border border-depenable focus:outline-none focus:ring-2 ring-offset-2 ring-black/30 px-3 py-2"
                                                value={ShulPolicy}
                                                onChange={(e) => setShulPolicy(e.target.value)}
                                            />
                                            <button className="ml-4 align-middle" onClick={UpdateShulPolicy}>
                                                <FaCheck className="text-dependable" />
                                            </button>
                                        </>
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label htmlFor={`has_mikvah`}>
                                        <input type={"checkbox"} id={`has_mikvah`} className="mr-3 w-4 h-4" checked={colony?.hasMikvah !== false} onChange={() => { toggleHasMikvah() }} />
                                        Has Mikvah
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label htmlFor={`openOnThu`}>
                                        <input type={"checkbox"} id={`openOnThu`} className="mr-3 w-4 h-4" checked={colony?.openOnThu === true} onChange={() => { toggleOpenOnThu() }} />
                                        Open on Thursdays
                                    </label>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div>
                <ul className="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400">
                    <li className="mr-2">
                        <Link
                            to={"/colony/" + colony.colony_id + "/users"}
                            className={`cursor-pointer inline-block p-4 rounded-t-lg ${currentTab === "users" ? "text-dependable bg-gray-100" : "hover:text-gray-600 hover:bg-gray-50"
                                } `}
                        >
                            Users
                        </Link>
                    </li>
                    <li className="mr-2">
                        <Link
                            to={"/colony/" + colony.colony_id + "/policies"}
                            className={`cursor-pointer inline-block p-4 rounded-t-lg ${currentTab === "policies" ? "text-dependable bg-gray-100" : "hover:text-gray-600 hover:bg-gray-50"
                                } `}
                        >
                            Policies
                        </Link>
                    </li>
                    <li className="mr-2">
                        <Link
                            to={"/colony/" + colony.colony_id + "/notes"}
                            className={`cursor-pointer inline-block p-4 rounded-t-lg ${currentTab === "notes" ? "text-dependable bg-gray-100" : "hover:text-gray-600 hover:bg-gray-50"
                                } `}
                        >
                            Notes
                        </Link>
                    </li>
                    <li className="mr-2">
                        <Link
                            to={"/colony/" + colony.colony_id + "/upcoming"}
                            className={`cursor-pointer inline-block p-4 rounded-t-lg ${currentTab === "upcoming" ? "text-dependable bg-gray-100" : "hover:text-gray-600 hover:bg-gray-50"
                                } `}
                        >
                            Upcoming Signups
                        </Link>
                    </li>
                    <li className="mr-2">
                        <Link
                            to={"/colony/" + colony.colony_id + "/history"}
                            className={`cursor-pointer inline-block p-4 rounded-t-lg ${currentTab === "history" ? "text-dependable bg-gray-100" : "hover:text-gray-600 hover:bg-gray-50"
                                } `}
                        >
                            History
                        </Link>
                    </li>
                </ul>
            </div>

            <div className="py-5">
                {currentTab === "policies" && <Policies getColony={getColony} colony_id={colony_id} policies={colony.policies} />}
                {currentTab === "users" && (
                    <Users getColony={getColony} colony_id={colony_id} colony={colony} users={colony.users} />
                )}
                {currentTab === "notes" && <Notes getColony={getColony} colony_id={colony_id} notes={colony.notes} />}
                {(currentTab === "history" || currentTab === "upcoming") && (
                    <History
                        isUpcoming={currentTab === "upcoming"}
                        setColHistory={setColHistory}
                        ColHistory={ColHistory}
                        colony_id={colony_id}
                        users={colony.users}
                    />
                )}
            </div>
            {
                EditHolidayOpen && (
                    <EditHoliday colony={colony} successCallback={getColony} close={() => setEditHolidayOpen(false)} />
                )
            }
        </div >
    );
};

export default Colony;
