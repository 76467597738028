import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ErrorComponent from "../+global/ErrorComponent";
import Get from "../../helpers/ajax/get";

const SignUpData = () => {
    document.title = "SignUp Data | Dependable Admin";

    const [hasError, setHasError] = useState(null);
    const [colonies, setColonies] = useState([]);

    const getColonies = async () => {
        try {
            var colonies = await Get("/api/colonies/minyan");
            setColonies(colonies.data);
        } catch (error) {
            setHasError(error.error || "an error occurred");
        }
    };

    useEffect(() => {
        getColonies();
    }, []);

    if (!!hasError) return <ErrorComponent error={hasError} />;

    return (
        <div className="max-w-6xl mx-auto text-left">
            <div className="py-5">
                <h2 className="text-4xl font-bold inline-block">Colony sign up info</h2>
            </div>
            <div className="py-5 grid gap-12 grid-cols-2">
                {colonies.map((colony) => {
                    return (
                        <div className="border-b border-depenable py-5 md:col-span-1 col-span-2">
                            <Link to={`/colony/${colony.colony_id}`} key={colony.colony_id} className="text-2xl text-dependable rounded-lg p-5 pl-0">
                                {colony.name}
                            </Link>
                            <p className="w-5/6"><b>Policy:</b> {colony.mikvahShulPolicy || "N/A"}</p>
                            <div className="md:pl-8 pt-5">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td className="md:px-3 py-1 font-bold">Units open</td>
                                            <td className="md:px-3 py-1">{colony.openUnits}</td>
                                        </tr>
                                        <tr>
                                            <td className="md:px-3 py-1 font-bold">People Coming</td>
                                            <td className="md:px-3 py-1">{colony.minyanRecords.shabbos.sureComing}</td>
                                        </tr>
                                        <tr>
                                            <td className="md:px-3 py-1 font-bold">People Coming if minyan</td>
                                            <td className="md:px-3 py-1">{colony.minyanRecords.shabbos.comingIfMinyan}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default SignUpData;
