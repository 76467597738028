import React from "react";
import { useAuth } from "./context/auth";
import { Outlet } from "react-router-dom";
import Login from "./components/+login";

function PrivateRoute() {
    const { authenticated } = useAuth();
    return authenticated ? <Outlet /> : <Login />;
}

export default PrivateRoute;
