import { useState } from "react";
import Post from "../../../helpers/ajax/post";
import ModelWrapper from "./ModelWrapper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

var inputClass = "w-full block mb-3 rounded-md border focus:outline-none focus:ring-2 ring-offset-2 ring-black/30 px-3 py-2";
var formButtonClass = "bg-black mt-5 block text-white w-full p-3 font-bold rounded-md focus:outline-none focus:ring-2 ring-offset-2 ring-black/30";

const NewUser = (props) => {
    const { close, successCallback, colony_id } = props;

    const [FirstName, setFirstName] = useState("");
    const [LastName, setLastName] = useState("");
    const [Email, setEmail] = useState("");
    const [Unit, setUnit] = useState("");
    const [Admin, setAdmin] = useState(false);

    const [hasError, setHasError] = useState(null);

    const addUser = async (ev) => {
        ev.preventDefault();
        try {
            await Post("/api/users/add", {
                firstName: FirstName,
                lastName: LastName,
                email: Email,
                unit: Unit,
                admin: Admin,
                colony_id: colony_id,
            });
            toast(`${FirstName} ${LastName} has been invited`);
            successCallback();
            close();
        } catch (error) {
            setHasError(error.error || "an error occurred");
        }
    };

    return (
        <ModelWrapper width={"w-96"} close={close} title={"Add User"}>
            <form onSubmit={addUser} className="pt-4">
                <input value={FirstName} onChange={(e) => setFirstName(e.target.value)} placeholder="First name" type="text" className={inputClass} />
                <input value={LastName} onChange={(e) => setLastName(e.target.value)} required placeholder="Last name" type="text" className={inputClass} />
                <input value={Email} onChange={(e) => setEmail(e.target.value)} required placeholder="Email" type="email" className={inputClass} />
                <input value={Unit} onChange={(e) => setUnit(e.target.value)} required placeholder="Unit #" type="text" className={inputClass} />
                <label htmlFor="role_admin" className="flex p-2 align-middle content-center self-center">
                    <input className="inline-block mr-3 w-5 h-5" id="role_admin" type="checkbox" checked={Admin} onChange={(e) => setAdmin(e.target.checked)} />
                    <p>Admin role</p>
                </label>
                <button className={formButtonClass}>Add User</button>
                {!!hasError && <p className="text-red-800 text-left mt-4">{hasError}</p>}
            </form>
        </ModelWrapper>
    );
};

export default NewUser;
