const Get = (url) =>
    new Promise((resolve, reject) => {
        return fetch(url)
            .then((response) => {
                response
                    .json()
                    .then(response.ok ? resolve : reject)
                    .catch(reject);
            })
            .catch(reject);
    });

export default Get;
