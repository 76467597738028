import { React, useState, useEffect, createContext, useContext } from 'react'
import Get from '../../helpers/ajax/get';
import Post from '../../helpers/ajax/post';
import { FaCheck, FaTimes, FaEdit } from "react-icons/fa";
const moment = require('moment');
const updateJobs = createContext();
let colonies, workers, workTypes;
var verifiers = 'David,Yittel,Tzivia'.split(',').sort();
let fetchJobs;
const JobsReview = () => {
    var [jobs, setJobs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [filter, setFilter] = useState({});
    const [error, setError] = useState("");
    useEffect(() => {
        fetchJobs = async (filter) => {
            try {
                ({ colonies, workers, workTypes } = (await Get('/api/work-ticket-status/getFormInfo')));
                const newJobs = await Get(`/api/work-ticket-status?page=${currentPage}&limit=10&filter=${JSON.stringify(filter)}`);
                setJobs([...jobs, ...newJobs]);
                setHasMore([...jobs, ...newJobs].length === (currentPage * 10));
                setError("");
            } catch (error) {
                setError((error.message ||
                    error.error));
            }
        };
        fetchJobs(filter);
        // eslint-disable-next-line
    }, [currentPage]);
    function refetch(filter) {
        setJobs([]);
        setCurrentPage(1);
        fetchJobs(filter);
    }
    return (
        <div>
            {error &&
                <div className="bg-red-100 border border-red-400 text-red-700 p-4 mb-2 max-w-4xl mx-auto rounded relative" role="alert">
                    <strong className="font-bold">Error: </strong>{' ' + error}
                </div>}
            <updateJobs.Provider value={(jobId, field, value) => {
                setFilter({ ...filter, [field]: value });
            }}>
                <FilterAccordion filter={filter} fetch={() => { refetch(filter); }} reset={() => {
                    setFilter({});
                    refetch({});
                }} />
            </updateJobs.Provider>
            <div className="flex-grow grid grid-cols-4 gap-4 items-center p-4 font-bold text-dependable max-w-4xl mx-auto">
                <p>Date</p>
                <p>Worker's Name</p>
                <p>Location</p>
                <p>Billing Status</p>
            </div>
            <updateJobs.Provider value={async (jobId, field, value) => {
                try {
                    await Post("/api/work-ticket-status/update/" + jobId, { field, value });
                    jobs = jobs.map((job) => {
                        if (job._id === jobId)
                            return { ...job, [field]: value, };
                        return job;
                    })
                    if (['timeFrom', 'timeTo'].includes(field)) {
                        jobs = jobs.map((job) => {
                            if (job._id === jobId) {
                                var newDuration = moment.utc(moment(job.timeTo, 'HH:mm').diff(moment(job.timeFrom, 'HH:mm'))).format('H:mm');
                                Post("/api/work-ticket-status/update/" + jobId, { field: 'duration', value: newDuration });
                                return {
                                    ...job, duration: newDuration
                                };
                            }
                            return job;
                        });
                    }
                    setJobs(jobs);
                } catch (error) {
                    throw error;
                }
            }}>
                {jobs.map((job) => (
                    <JobAccordion key={job._id} job={job} />
                ))}
            </updateJobs.Provider>
            <div className="flex justify-center mt-4">
                <button onClick={() => { setCurrentPage(prevPage => prevPage + 1); }} disabled={!hasMore} className={`px-4 py-2 rounded-md ${hasMore ?
                    'bg-dependable-green text-white hover:bg-dependable-green-dark' : 'bg-gray-300 text-gray-500 cursor-not-allowed'}`}>Show More</button>
            </div>
        </div>
    )
}
const JobAccordion = ({ job }) => {
    const [expanded, setExpanded] = useState(false);
    const handleToggle = () => setExpanded(!expanded);
    const [error, setError] = useState("");
    const [workersNames, setWorkers] = useState([]);
    const [places, setPlaces] = useState([]);
    const [jobTypes, setJobTypes] = useState([]);
    useEffect(() => {
        const setHooks = async () => {
            setWorkers((await workers) ||
                []);
            setPlaces((await colonies) ||
                []);
            setJobTypes((await workTypes) ||
                []);
        };
        setHooks();
        // eslint-disable-next-line
    }, [workers, colonies, workTypes]);
    return (<div className={`bg-white shadow-md rounded-lg mb-4 overflow-hidden max-w-4xl mx-auto ${expanded ?
        'border-2 border-dependable-green' : ''}`}>
        <div className="flex justify-between items-center p-4 cursor-pointer" onClick={handleToggle}>
            <div className="flex-grow grid grid-cols-4 gap-4">
                {[moment(job.date).format('MM/DD/YYYY'), job.workersName, job.place]
                    .map((p, i) => (<p key={i} className="text-sm md:text-base text-dependable font-semibold">{p}</p>))}
                <p className={`text-sm md:text-base font-semibold ${(job?.billingStatus === 'Billed' &&
                    (job?.verified && job?.verified !== 'No')) ?
                    'text-dependable-green' : (job?.billingStatus === 'Unbilled' || !job?.billingStatus ?
                        'text-red-500' : 'text-dependable')}`}>{(job?.verified && job?.verified !== 'No') && job?.billingStatus === 'Billed' ?
                            'Verified & Billed' : job?.billingStatus
                            || 'Unbilled'}</p>
            </div>
            <div className={`text-dependable-green transition-transform duration-300 ${expanded ?
                'transform rotate-180' : ''}`}>▼</div>
        </div>
        <div className={`overflow-hidden transition-max-height duration-300 ease-in-out ${expanded ?
            '' : 'max-h-0'}`}>
            <div className="p-4 bg-gray-50 border-t text-left border-gray-200 grid grid-cols-2 grid-flow-row gap-4">
                <div className="text-center font-semibold text-dependable col-span-2 overflow-hidden text-ellipsis whitespace-nowrap">Ticket #: {job.ticket}</div>
                <PrintValue job={job} fieldName="Billing Status" objectFieldName='billingStatus' inputType='dd' editable={true} dropDownValues={['Billed', 'Unbilled', 'Unbillable']} setError={setError} />{/* 1 */}
                <PrintValue job={job} fieldName="Verified" objectFieldName='verified' inputType='dd' editable={true} dropDownValues={['No', ...verifiers.map((v) => "By " + v)]} setError={setError} />{/* 8 */}
                <hr className="border-t border-gray-300 my-2 w-full col-span-2" />
                <PrintValue job={job} fieldName="Submitter's Name" objectFieldName='submittersName' inputType='text' editable={false} dropDownValues={[]} setError={setError} />{/*2*/}
                <PrintValue job={job} fieldName='From' objectFieldName='timeFrom' inputType='time' editable={true} dropDownValues={[]} setError={setError} />{/* 9 */}
                <PrintValue job={job} fieldName='Who worked' objectFieldName='workersName' inputType='dd' editable={true} dropDownValues={[...workersNames, 'Other']} setError={setError} />{/* 3 */}
                <PrintValue job={job} fieldName='To' objectFieldName='timeTo' inputType='time' editable={true} dropDownValues={[]} setError={setError} />{/* 10 */}
                <PrintValue job={job} fieldName='Number of People' objectFieldName='amountOfPeople' inputType='number' editable={true} dropDownValues={[]} setError={setError} />{/* 4 */}
                <PrintValue job={job} fieldName='Duration' objectFieldName='duration' inputType='none' editable={false} dropDownValues={[]} setError={setError} />{/* 11 */}
                <PrintValue job={job} fieldName='Number of Helpers' objectFieldName='amountOfHelpers' inputType='number' editable={true} dropDownValues={[]} setError={setError} />{/* 5 */}
                <PrintValue job={job} fieldName='Type of Work' objectFieldName='jobType' inputType='dd' editable={true} dropDownValues={jobTypes} setError={setError} />{/* 12 */}
                <PrintValue job={job} fieldName='Location' objectFieldName='place' inputType='dd' editable={true} dropDownValues={[...places, 'Other']} setError={setError} />{/* 6 */}
                <PrintValue job={job} fieldName='Job Done' objectFieldName='jobDone' inputType='bool' editable={true} dropDownValues={[]} setError={setError} />{/* 13 */}
                <PrintValue job={job} fieldName='Date' objectFieldName='date' inputType='date' editable={true} dropDownValues={[]} setError={setError} />{/* 7 */}
                <PrintValue job={job} fieldName='Task Number' objectFieldName='taskNumber' inputType='text' editable={true} dropDownValues={[]} setError={setError} />{/* 14 */}
                <div className="text-center col-span-2 overflow-hidden">
                    <PrintValue job={job} fieldName='Note' objectFieldName='note' inputType='textarea' editable={true} dropDownValues={[]} setError={setError} />{/* 15 */}
                </div>
            </div>
            {error && (
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                    <span className="block sm:inline">
                        <b>Error:</b> {error}
                    </span>
                    <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                        <svg className="fill-current h-6 w-6 text-red-500" role="button" onClick={() => setError("")} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg>
                    </span>
                </div>)}
        </div>
    </div>)
}
const PrintValue = ({ filter = false, job, fieldName, objectFieldName, inputType = 'text', editable = true, dropDownValues = [], setError }) => {
    const jobUpdator = useContext(updateJobs);
    const [value, setValue] = useState('Not set');
    useEffect(() => {
        setValue(objectFieldName in job ?
            job[objectFieldName] : objectFieldName === 'verified' && !filter ?
                'No' : 'Not set');
        // eslint-disable-next-line 
    }, [job]);
    useEffect(() => {
        if (filter && value === 'Not set')
            setValue('Any');
        // eslint-disable-next-line
    }, [value]);
    const [editing, setEditing] = useState(false);
    const changeValue = async (e) => {
        try {
            if (['date', 'time'].includes(inputType))
                if (!filter)
                    await jobUpdator(job._id, objectFieldName, e.target.value);
                else jobUpdator(job._id, objectFieldName, e.target.value);
            setValue(e.target.value);
        } catch (error) {
            setError((error.message ||
                error.error));
        }
    }
    return (<p className="mb-2">
        <span className="font-semibold text-dependable">{fieldName}: </span>
        <span className="text-gray-700">{!editing && (
            <>
                {' ' + (inputType === 'date' && !['Any', 'Not set'].includes(value) ?
                    (moment(value).format('MM/DD/YYYY') !== 'Invalid date' ?
                        moment(value).format('MM/DD/YYYY') : 'Not set') : (inputType === 'time' && !['Any', 'Not set'].includes(value) ?
                            moment(value, "HH:mm").format('h:mm A') : (inputType === 'bool' ?
                                ((!['Any', 'Not set'].includes(value) && value) ?
                                    'Yes' : 'No') : value)))
                }
                {editable &&
                    <button className="ml-4 align-middle" onClick={async () => {
                        if (inputType === 'bool')
                            try {
                                await jobUpdator(job._id, objectFieldName, !value);
                            } catch (error) {
                                setError((error.message ||
                                    error.error));
                            }
                        else setEditing(true)
                    }} >
                        <FaEdit className="text-dependable" />
                    </button>}
            </>)}
            {(editing && !['dd', 'bool'].includes(inputType)) &&
                (<div className="relative">{inputType === 'textarea' ?
                    (<textarea className="w-full block rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-dependable focus:border-transparent shadow-sm px-4 py-2 text-gray-700 transition duration-150 ease-in-out" value={value} onChange={changeValue} rows={4} />) : (
                        <input type={inputType} className="w-full block rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-dependable focus:border-transparent shadow-sm px-4 py-2 text-gray-700 transition duration-150 ease-in-out" value={(inputType === 'date' ?
                            moment(value).format('YYYY-MM-DD') : value)} onChange={changeValue} onBlur={() => {
                                if (['date', 'time'].includes(inputType))
                                    setEditing(false);
                            }} />
                    )}
                    {!['date', 'time'].includes(inputType) &&
                        <>
                            <button className="absolute text-lg p-1 focus:outline-none top-1/2 transform -translate-y-1/2 right-0">
                                <FaTimes className='text-dependable hover:text-red-500' onClick={() => {
                                    setValue(objectFieldName in job ?
                                        job[objectFieldName] : 'Not set');
                                    setEditing(false);
                                }} />
                            </button>
                            <button className="absolute text-lg p-1 focus:outline-none top-1/2 transform -translate-y-1/2 right-6 disabled:opacity-50">
                                <FaCheck className='text-dependable hover:text-green-500' onClick={async () => {
                                    try {
                                        if (!filter)
                                            await jobUpdator(job._id, objectFieldName, value);
                                        else jobUpdator(job._id, objectFieldName, value);
                                        setEditing(false);
                                    } catch (error) {
                                        setError((error.message ||
                                            error.error));
                                    }
                                }} />
                            </button>
                        </>}
                </div>)}
            {(editing && inputType === 'dd') &&
                (<select className="w-full block rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-dependable focus:border-transparent shadow-sm px-4 py-2 text-gray-700 transition duration-150 ease-in-out" value={value} onChange={async (e) => {
                    try {
                        if (!filter)
                            await jobUpdator(job._id, objectFieldName, e.target.value);
                        else jobUpdator(job._id, objectFieldName, e.target.value);
                        setValue((e.target.value ||
                            'Not set'));
                        setEditing(false);
                    } catch (error) {
                        setError((error.message ||
                            error.error));
                    }
                }}>
                    <option value="">Please Select</option>
                    {dropDownValues.map((opt, i) => (
                        <option key={i} value={opt}>{opt}</option>))}
                </select>)}
        </span>
    </p>)
}
const FilterAccordion = ({ filter, fetch, reset }) => {
    const [expanded, setExpanded] = useState(false);
    const handleToggle = () => setExpanded(!expanded);
    const [workersNames, setWorkers] = useState([]);
    const [places, setPlaces] = useState([]);
    const [jobTypes, setJobTypes] = useState([]);
    useEffect(() => {
        const setHooks = async () => {
            setWorkers((await workers) ||
                []);
            setPlaces((await colonies) ||
                []);
            setJobTypes((await workTypes) ||
                []);
        };
        setHooks();
        // eslint-disable-next-line
    }, [workers, colonies, workTypes]);
    return (<div className={`bg-white shadow-md rounded-lg mb-4 overflow-hidden text-center max-w-4xl mx-auto border-2 border-gray-400`}>
        <div className="flex justify-between items-center p-4 cursor-pointer bg-gray-100 hover:bg-gray-200 transition-colors duration-300" onClick={handleToggle}>
            <span className="flex-grow text-center font-semibold text-lg text-gray-800">Filter Settings</span>
            <div className={`text-dependable-green transition-transform duration-300 ${expanded ?
                'transform rotate-180' : ''}`}>▼</div>
        </div>
        <div className={`overflow-hidden transition-max-height duration-300 ease-in-out ${expanded ?
            '' : 'max-h-0'}`}>
            <div className="p-4 bg-gray-50 border-t text-center border-gray-200 grid grid-cols-2 grid-flow-row gap-4">
                <PrintValue filter={true} job={filter} fieldName={"Submitter's name"} objectFieldName={"submittersName"} editable={true} inputType='text' dropDownValues={[]} setError={() => { }} />
                <PrintValue filter={true} job={filter} fieldName={"Worker's name"} objectFieldName={"workersName"} editable={true} inputType='dd' dropDownValues={['Any', ...workersNames, 'Other']} setError={() => { }} />
                <PrintValue filter={true} job={filter} fieldName={"Task Number"} objectFieldName={"taskNumber"} editable={true} inputType='text' dropDownValues={[]} setError={() => { }} />
                <PrintValue filter={true} job={filter} fieldName={"Ticket Number"} objectFieldName={"ticket"} editable={true} inputType='text' dropDownValues={[]} setError={() => { }} />
                <PrintValue filter={true} job={filter} fieldName={"From"} objectFieldName={"from"} editable={true} inputType='date' dropDownValues={[]} setError={() => { }} />
                <PrintValue filter={true} job={filter} fieldName={"To"} objectFieldName={"to"} editable={true} inputType='date' dropDownValues={[]} setError={() => { }} />
                <PrintValue filter={true} job={filter} fieldName={"Location"} objectFieldName={"place"} editable={true} inputType='dd' dropDownValues={['Any', ...places, 'Other']} setError={() => { }} />
                <PrintValue filter={true} job={filter} fieldName={"Type of Work"} objectFieldName={"jobType"} editable={true} inputType='dd' dropDownValues={['Any', ...jobTypes, 'Other']} setError={() => { }} />
                <PrintValue filter={true} job={filter} fieldName={'Verified'} objectFieldName={'verified'} editable={true} inputType='dd' dropDownValues={['Any', 'Verified', 'Not verified', ...verifiers.map(v => "Verified By " + v)]} setError={() => { }} />
                <PrintValue filter={true} job={filter} fieldName={'Billing Status'} objectFieldName={'billingStatus'} editable={true} inputType='dd' dropDownValues={['Any', 'Billed', 'Unbilled', 'Unbillable']} setError={() => { }} />
                <div className="col-span-2 flex justify-end space-x-4 mt-4">
                    <button className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-300/80 transition-colors" onClick={() => { reset(); }}>Reset</button>
                    <button className="px-4 py-2 bg-dependable-green text-white rounded hover:bg-dependable-green/80 transition-colors" onClick={() => { fetch() }}>OK</button>
                </div>
            </div>
        </div>
    </div>)
}
export default JobsReview