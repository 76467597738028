import { useState } from "react";
import Policy from "../../+global/modals/Policy";
import useConfirm from "../../../hooks/useConfirm";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import Delete from "../../../helpers/ajax/delete";
import NewPolicy from "../../+global/modals/NewPolicy";
import { toast } from "react-toastify";

const Policies = (props) => {
    const { policies, getColony, colony_id } = props;

    const [PolicyOpen, setPolicyOpen] = useState(null);
    const [EditMode, setEditMode] = useState(false);
    const [AddNewPolicy, setAddNewPolicy] = useState(false);

    const { confirm } = useConfirm();

    const DeletePolicy = async (policy_id, policy_title) => {
        const confirmDelete = await confirm({
            text: `Are you sure you want to delete <b>${policy_title}</b>?`,
            confirmText: "Delete",
            title: "Delete Policy",
        });

        if (confirmDelete) {
            try {
                await Delete(`/api/policies/${policy_id}`);
                toast(`Policy deleted!`);
                getColony();
            } catch (error) {
                console.log(error);
            }
        }
    };

    return (
        <div>
            <div className="flex justify-between px-4 mb-8 items-center">
                <h3 className="font-bold text-2xl">Policies</h3>
                <button
                    className="bg-dependable text-white py-3 px-5 rounded-md "
                    onClick={() => {
                        setAddNewPolicy(true);
                    }}
                >
                    Add Policy
                </button>
            </div>

            <div className="grid grid-cols-2 gap-4 mt-4">
                {policies.map((policy) => {
                    return (
                        <div className="col-span-2 md:col-span-1 rounded-lg shadow-lg border p-3 flex content-center justify-between" key={policy._id}>
                            <button
                                onClick={() => {
                                    setEditMode(false);
                                    setPolicyOpen(policy._id);
                                }}
                                className="text-lg"
                            >
                                {policy.title}
                            </button>
                            <div className="flex">
                                <button
                                    onClick={() => {
                                        setEditMode(true);
                                        setPolicyOpen(policy._id);
                                    }}
                                    className="mr-1 text-lg p-2 border rounded float-right ring-black/30 focus:ring-2 ring-offset-2 focus:outline-none"
                                >
                                    <FaEdit className="text-dependable" />
                                </button>
                                <button onClick={() => DeletePolicy(policy._id, policy.title)} className="text-lg p-2 border rounded float-right ring-black/30 focus:ring-2 ring-offset-2 focus:outline-none">
                                    <FaTrashAlt className="text-dependable" />
                                </button>
                            </div>
                        </div>
                    );
                })}
            </div>
            {AddNewPolicy && <NewPolicy close={() => setAddNewPolicy(false)} colony_id={colony_id} getColony={getColony} />}
            {PolicyOpen && <Policy {...props} editMode={EditMode} policy={policies.find((p) => p._id === PolicyOpen)} close={() => setPolicyOpen(null)} />}
        </div>
    );
};

export default Policies;
