import { useState } from "react";
import Post from "../../../helpers/ajax/post";
import ModelWrapper from "./ModelWrapper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

var formButtonClass = "bg-black mt-5 block text-white w-full p-3 font-bold rounded-md focus:outline-none focus:ring-2 ring-offset-2 ring-black/30";

const EditHoliday = (props) => {
    const { close, successCallback, colony } = props;
    const { allowedHolidays } = colony;

    const [hasError, setHasError] = useState(null);
    const [SelectedHolidays, setSelectedHolidays] = useState(colony.holidays.map((holidayObj) => holidayObj.displayName));

    const handleCheckClick = (holidayObj) => {
        var indexOf = SelectedHolidays.indexOf(holidayObj.displayName);
        if (indexOf > -1) {
            setSelectedHolidays(SelectedHolidays.filter((e) => e !== holidayObj.displayName));
        } else {
            setSelectedHolidays(SelectedHolidays.concat([holidayObj.displayName]));
        }
    };

    const updateHolidays = async () => {
        try {
            var newHolidays = allowedHolidays.filter((holidayObj) => SelectedHolidays.includes(holidayObj.displayName));
            await Post(`/api/colonies/${colony.colony_id}/setHolidays`, {
                holidays: newHolidays,
            });
            toast("Updated enabled hilodays");
            successCallback();
            close();
        } catch (error) {
            setHasError(error.error || "an error occurred");
        }
    };

    return (
        <ModelWrapper width={"w-96"} close={close} title={"Select Holidays"}>
            <div>
                {allowedHolidays.map((holiday, index) => (
                    <div key={index}>
                        <label htmlFor={holiday.name.replaceAll(" ", "_")} className="inline-flex p-2 align-middle content-center self-center">
                            <input checked={SelectedHolidays.includes(holiday.displayName)} onChange={() => handleCheckClick(holiday)} className="inline-block mr-3 w-5 h-5" type="checkbox" id={holiday.name.replaceAll(" ", "_")} />
                            {holiday.displayName}
                        </label>
                    </div>
                ))}
                <button onClick={updateHolidays} className={formButtonClass}>
                    Update Holidays
                </button>
                {!!hasError && <p className="text-red-800 text-left mt-4">{hasError}</p>}
            </div>
        </ModelWrapper>
    );
};

export default EditHoliday;
