import "./App.css";

import { AuthContext } from "./context/auth";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import Home from "./components/+home";
import Login from "./components/+login";
import PrivateRoute from "./PrivateRoute";
import { useEffect, useState } from "react";
import Get from "./helpers/ajax/get";
import Header from "./components/+global/Header";
import Colony from "./components/+colony";
import { ConfirmContextProvider } from "./store/ConfirmContextProvider";
import ConfirmDialog from "./components/+global/modals/ConfirmDialog";
import { ToastContainer } from "react-toastify";
import SignUpData from "./components/+signup";
import JobsReview from "./components/+jobsReview";

function App() {
    const [authenticated, setAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);
    const navigate = useNavigate();

    const loadUserData = async () => {
        try {
            var userData = await Get("/api/self");
            setUser(userData);
            setAuthenticated(true);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const logout = async () => {
        Get("/logout")
            .catch(console.log)
            .finally(() => {
                setUser(null);
                setAuthenticated(false);
                navigate("/");
            });
    };

    useEffect(() => {
        loadUserData();
    }, []);

    return (
        <div className="text-center">
            {loading && <div>Loading</div>}

            {!loading && (
                <AuthContext.Provider value={{ authenticated, setAuthenticated, user, loadUserData, logout }}>
                    <ConfirmContextProvider>
                        <Header />
                        <div className="px-5 pb-10">
                            <Routes>
                                <Route exact path="/login" element={authenticated ? <Navigate to={`/`} /> : <Login />} />
                                <Route path="/" element={<PrivateRoute />}>
                                    <Route exact path="/" element={<Home />} />
                                    <Route exact path="/signupdata" element={<SignUpData />} />
                                    <Route exact path="/colony/:colony_id" element={<Colony />} />
                                    <Route exact path="/colony/:colony_id/:section" element={<Colony />} />
                                    <Route exact path="/work-ticket-status" element={<JobsReview />} />
                                    <Route path="*" element={<Navigate to={`/`} />} />
                                </Route>
                            </Routes>
                        </div>
                        <ConfirmDialog />
                    </ConfirmContextProvider>
                    <ToastContainer
                        position="bottom-left"
                        autoClose={2500}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable={false}
                        pauseOnHover
                    />
                </AuthContext.Provider>
            )}
        </div>
    );
}

export default App;
